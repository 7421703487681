import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import axios from "axios";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SidebarResponsive } from "components/sidebar/SidebarApp";
import PropTypes from "prop-types";

// Assets
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import routes from "routesApp";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "utils/userSlice";

export default function NavbarLinksUser(props) {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "gray.400");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const bg = useColorModeValue("brand.500", "gray.100");
  const color = useColorModeValue("white", "brand.500");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const firstName = userState.hasOwnProperty("firstName")
    ? userState.firstName
    : "";
  const lastName = userState.hasOwnProperty("lastName")
    ? userState.lastName
    : "";

  const logOut = () => {
    dispatch(setUser({}));
    navigate("/p2pauth/login", { replace: true });
  };

  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
  const [notifications, setNotifications] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(3);
  const [orderBy, setOrderBy] = useState("createdAt");

  const getNotifications = async () => {
    try {
      const { data: response } = await axios.get(
        SERVER_URL +
        "/notifications?from=" +
        from +
        "&limit=" +
        limit +
        "&orderBy=" +
        orderBy +
        "&sortOrder=" +
        sortOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        return;
      }
      if (!response.success) {
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setNotifications(response.data.notifications);
      }
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    // getNotifications();
  }, []);

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      justifyContent={"space-between"}
    >
      {/*<SearchBar
        mb={secondary ? { base: "10px", md: "unset" } : "unset"}
        me='10px'
        borderRadius='30px'
      />*/}

      <SidebarResponsive routes={routes} />
      <Menu>
        {/*<MenuButton p="0px">
          <Icon
            mt="6px"
            as={MdNotificationsNone}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>*/}
        {/*<MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: "30px", md: "unset" }}
          minW={{ base: "unset", md: "400px", xl: "450px" }}
          maxW={{ base: "360px", md: "unset" }}
        >
          <Flex jusitfy="space-between" w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
              <Link to="/admin/notifications">See All</Link>
            </Text>
          </Flex>
          <Flex flexDirection="column">
            {notifications.length > 0 && notifications.map((notification) => {
              return (
                <MenuItem
                  key={notification._id}
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  px="0"
                  borderRadius="8px"
                  mb="10px"
                >
                  <ItemContent info={notification.message} date={notification.createdAt} />
                </MenuItem>
              )
            })}
          </Flex>
        </MenuList> */}
      </Menu>

      <Menu>
        {/* <MenuButton p="0px">
          <Icon
            mt="6px"
            as={MdInfoOutline}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>*/}
        {/*<MenuList
          boxShadow={shadow}
          p="20px"
          me={{ base: "30px", md: "unset" }}
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          minW={{ base: "unset" }}
          maxW={{ base: "360px", md: "unset" }}
        >
          <Flex flexDirection="column">
            <a w="100%" href="">
              <Button
                w="100%"
                h="44px"
                mb="10px"
                border="1px solid"
                bg="transparent"
                borderColor={borderButton}
              >
                Go to Help Center
              </Button>
            </a>
          </Flex>
        </MenuList>*/}
      </Menu>

      {/* <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === "light" ? IoMdMoon : IoMdSunny}
        />
      </Button> */}
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={firstName + " " + lastName}
            bg="brand.500"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hello {firstName}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color={textColor}
              borderRadius="8px"
              px="14px"
            >
              <Button
                fontSize="sm"
                bg={bg}
                color={color}
                onClick={(e) => {
                  e.preventDefault();
                  logOut();
                }}
              >
                Log out
              </Button>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

NavbarLinksUser.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
