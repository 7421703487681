import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Heading,
  Flex,
  Text,
  Stack,
  Image,
  Icon,
  Box,
  useToast,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
import illustrationImage from "assets/images/login1.png";
import Logo from "assets/images/logo.svg";
import { FaEnvelope } from "react-icons/fa";

function VerifyEmail() {
  return (
    <>
      <Helmet>
        <title>Verify</title>
        <meta name="description" content=" Create an account" />
        <meta name="keywords" content="" />
      </Helmet>

      <DefaultAuth illustrationImage={illustrationImage} heading="Peer 2 Peer Verification">

        <Flex width={"100%"} justify={"center"}>
          <Stack
            spacing={4}
            mx={"auto"}
            mt={{ sm: "30px", md: "0px" }}
            w={{ base: "95%", sm: "90%", md: "60%", lg: "50%" }}
          >
            <Stack p={2} align={"left"}>
              <Image src={Logo} width="70px" height="auto" />
              <Heading fontFamily="ManropeSemiBold" fontWeight={"bold"} size={{ sm: "sm", md: "md" }} textAlign={"center"} color="#45005E" mt="5">
                One more Step!!!
              </Heading>

              <Box width="100%" textAlign="center">
                <Text mt={"5"} mb="5" textAlign={"center"} fontFamily={"ManropeRegular"} fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                  We need to verify your email, please check your email and click on the verification link sent to you
                </Text>
                <Icon as={FaEnvelope} height="45px" width="45px" color="brand.500" />
              </Box>
            </Stack>

          </Stack>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default VerifyEmail;
