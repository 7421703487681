import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Tag,
  Center,
  Button,
  Heading,
  Icon,
  HStack,
  VStack,
  Input,
  Flex,
  Stack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  //Link,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  List,
  ListItem,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Skeleton,
  SkeletonText,
  useToast,
  useClipboard,
  useDisclosure
} from "@chakra-ui/react";
import { CopyIcon, EmailIcon } from "@chakra-ui/icons";
import Card from "components/card/Card";
import CardBg from "assets/images/ribbon.svg";
import { SubmitKYC } from "services/userService";
import { SubmitRecord, GetOtherInstitutions } from "services/institutionService";
import { GetVerifiableRecords, GetVerificationRequests, GetVerifierRequests, SendVerificationInvite, AcceptVerificationInvite  } from "services/certificateService";
import { useDispatch } from "react-redux";
import { setUser } from "utils/userSlice";
import { SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';

import { FacebookShare, TwitterShare, LinkedinShare, WhatsappShare, TelegramShare, EmailShare } from 'react-share-kit';

const { DateTime } = require("luxon");

export default function Dashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAcceptOpen, onOpen: onAcceptOpen, onClose: onAcceptClose } = useDisclosure();
  const { isOpen: isKycOpen,  onOpen: onKycOpen, onClose: onKycClose } = useDisclosure();
  const { isOpen: isBreakdownOpen,  onOpen: onBreakdownOpen, onClose: onBreakdownClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const [shareUrl, setShareUrl] = useState('');
  const [hashtags, setHashtags] = useState(["Verify Credential", "Akowe Credential Verification"]);
  const { onCopy, hasCopied } = useClipboard(shareUrl);
  
  const [sendInviteLoading, setSendInviteLoading] = useState(false);
  const [acceptInviteLoading, setAcceptInviteLoading] = useState(false);

  const [kycData, setKycData] = useState({
    firstName: userState.firstName? userState.firstName: "",
    lastName: userState.lastName? userState.lastName: "",
    middleName: userState.middleName? userState.middleName: "",
    nin: ""
  });

  const [recordData, setRecordData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    institute: ""
  });

  const [inviteData, setInviteData] = useState({
    emailAddress: "",
  });

  const [verifiableId, setVerifiableId] = useState("");
  const [inviteId, setInviteId] = useState("");

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [verificationRequestLoading, setVerificationRequestLoading] = useState(false);
  
  const [recordStat, setRecordStat] = useState({
    academicRecord: 0,
    verifiedRecords: 0
  });
  const [records, setRecords] = useState(null);
  const [verifierRequests, setVerifierRequests] = useState(null);
  const [institutions, setIntitutions] = useState([]);
  const [verificationRequests, setVerificationRequests] = useState(null);
  
  const getInstitutions = async () => {
    try {
      setSubmitLoading(true);
      const response = await GetOtherInstitutions();
      setSubmitLoading(false);
      if (response.success) {
         const institutionData = response?.data.map((institution)=> {
          return {label: institution.institutionName, value: institution._id}
         })
        setIntitutions(institutionData);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const getVerifiableRecords = async () => {
    try {
      setLoading(true);
      const response = await GetVerifiableRecords(token);
      setLoading(false);
      if (response.success) {
        setRecords(response?.data);
        const verifiedCount = response?.data.filter(item => item.verified).length;
        setRecordStat({
          academicRecord: response?.data.length,
          verifiedRecords: verifiedCount
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getVerificationRequests = async () => {
    try {
      setVerificationRequestLoading(true);
      const response = await GetVerificationRequests(token);
      setVerificationRequestLoading(false);
      if (response.success) {
        setVerificationRequests(response?.data);
      }
    } catch (error) {
      setVerificationRequestLoading(false);
    }
  };
  
  const getVerifierRequests = async () => {
    try {
      setLoading(true);
      const response = await GetVerifierRequests(token);
      setLoading(false);
      if (response.success) {
        setVerifierRequests(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmitKYC = async () => {
    try {
      setLoading(true);
      const response = await SubmitKYC(kycData, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: "KYC has been verified successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        const user  = userState;
        dispatch(setUser({...user, firstName: kycData.firstName, middleName: kycData.middleName, lastName: kycData.lastName, kycVerified: true}));
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  }

  const handleSendInvite = async () => {
    try {
      setSendInviteLoading(true);
      const response = await SendVerificationInvite(inviteData, verifiableId, token);
      setSendInviteLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: "Verification invite has been sent successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setSendInviteLoading(false);
    }
  }

  const handleAcceptInvite = async () => {
    try {

      if(!userState.kycVerified){
        onKycOpen();
        toast({
          title: "Please complete KYC first",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setAcceptInviteLoading(true);
      const response = await AcceptVerificationInvite(inviteId, token);
      setAcceptInviteLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: "Verification invite has been accepted",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onAcceptClose();
        getVerifierRequests();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setAcceptInviteLoading(false);
    }
}

const handleSubmitRecord = async () => {
  try {
    setSubmitLoading(true);
    const response = await SubmitRecord(recordData, token);
    setSubmitLoading(false);
    if (response.error || !response.success) {
      toast({
        title: response.error ? response.error : (response.message ? response.message : "An error occured"),
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }
    if (response.success) {
      toast({
        title: "Request has been submitted, we will notify you as soon when record is available for verification",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      onDrawerClose();
    }
  } catch (error) {
    toast({
      title: error.message || "An error occured",
      status: "error",
      duration: 9000,
      position: "top-right",
      isClosable: true,
    });
    setSubmitLoading(false);
  }
}

const handleGoToVerify = async (inviteCode) => {
  navigate("/p2p/credentials/verify/" + inviteCode);
}

  useEffect(() => {
    getVerifiableRecords();
    getVerificationRequests();
    getVerifierRequests();
    getInstitutions();
  }, []);


  useEffect(() => {
    if (shareUrl === "") return;
    onCopy();
  }, [shareUrl]);

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromISO(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_MED);
  };

  return (
    <> 
    <Modal onClose={onClose} size={"2xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Verification Invite</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              width="100%"
            > 
               <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Email Address</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="Email Address"
                      defaultValue={inviteData.emailAddress}
                      onChange={(e) => {
                        setInviteData(prevInviteData => ({
                          ...prevInviteData,
                          emailAddress: e.target.value,
                        }));
                      }}
                    />
               </FormControl>

            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="brand" isLoading={sendInviteLoading} loadingText="..Sending" onClick={handleSendInvite}>Send Invite</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal onClose={onAcceptClose} size={"2xl"} isOpen={isAcceptOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Accept Verification Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              width="100%"
            > 
              <Text size={{ sm: "md", md: "lg" }} textAlign={"center"}>Click on Proceed to accept invite to verify candidate record</Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button  mb="5" variant="brand" isLoading={acceptInviteLoading} loadingText="Wait..." onClick={handleAcceptInvite}>Proceed</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      
      <Modal onClose={onBreakdownClose} size={"2xl"} isOpen={isBreakdownOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Academic Credential Score Breakdown</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              width="100%"
              pb="20"
            > 
               <HStack width="100%">
                  <Box><Text fontSize={{ sm: "md", md: "lg" }} fontWeight={"Bold"}> Direct Integration with Tertiary Institutions</Text></Box>
                  <Box> <Tag fontSize={{ sm: "md", md: "lg" }} background={"brand.500"} color="white"> 50% </Tag></Box>
               </HStack>
             
               <HStack width="100%" mt="5">
                  <Box><Text fontSize={{ sm: "md", md: "lg" }} fontWeight={"Bold"}> Public Academic Records</Text></Box>
                  <Box> <Tag fontSize={{ sm: "md", md: "lg" }} background={"brand.500"} color="white">  20% </Tag></Box>
               </HStack>

               <HStack width="100%" mt="5">
                  <Box><Text fontSize={{ sm: "md", md: "lg" }} fontWeight={"Bold"}> Peer 2 Peer Verification</Text></Box>
                  <Box> <Tag fontSize={{ sm: "md", md: "lg" }} background={"brand.500"} color="white">  20% </Tag></Box>
               </HStack>

               <HStack width="100%" mt="5">
                  <Box><Text fontSize={{ sm: "md", md: "lg" }} fontWeight={"Bold"}> NIN Verification</Text></Box>
                  <Box> <Tag fontSize={{ sm: "md", md: "lg" }} background={"brand.500"} color="white">  10% </Tag></Box>
               </HStack>

            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>


      <Modal onClose={onKycClose} size={"2xl"} isOpen={isKycOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verify Your Identity</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <SimpleGrid
                width="100%"
                columns={{ base: 1, md: 2 }}
                spacing="6"
                mb="20"
                mt="5">
                <Box>
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">First Name</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="First Name"
                      defaultValue={kycData.firstName}
                      onChange={(e) => {
                        setKycData(prevKycData => ({
                          ...prevKycData,
                          firstName: e.target.value,
                        }));
                      }}
                    />
                  </FormControl>
                </Box>

                <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Middle Name</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="Middle Name"
                      defaultValue={kycData.middleName}
                      onChange={(e) => {
                        setKycData(prevKycData => ({
                          ...prevKycData,
                          middleName: e.target.value,
                        }));
                      }}
                    />
                  </FormControl>


                  <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Last Name</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="Last Name"
                      defaultValue={kycData.lastName}
                      onChange={(e) => {
                        setKycData(prevKycData => ({
                          ...prevKycData,
                          lastName: e.target.value,
                        }));
                      }}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">NIN</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="NIN"
                      defaultValue={kycData.nin}
                      onChange={(e) => {
                        setKycData(prevKycData => ({
                          ...prevKycData,
                          nin: e.target.value,
                        }));
                      }}
                    />
                  </FormControl>

                <Box>
                  <FormLabel fontWeight="bold">&nbsp;</FormLabel>
                  <Button variant={"brand"} onClick={handleSubmitKYC} isLoading={loading} loadingText={"...Wait"}> Proceed </Button>
                </Box>
              </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button variant="brand" isLoading={acceptInviteLoading} loadingText="Wait..." onClick={handleAcceptInvite}>Proceed</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E">Request Academic Record</DrawerHeader>
          <DrawerBody>

          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Select Institution</FormLabel>
              <SearchableSelect 
              options={institutions}
              placeholder="Select Institution" 
              onChange={(value) => {
                setRecordData(prevRecordData => ({
                ...prevRecordData,
                institute: value,
                }));
                
              }}
              setRecordData={setRecordData}
            />
            </FormControl>
          </Box>

          <Box mt="3">
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">First Name</FormLabel>
              <Input
                bg="white"
                variant="main"
                maxLength={200}
                placeholder="First Name"
                defaultValue={recordData.firstName}
                onChange={(e) => {
                  setRecordData(prevRecordData => ({
                    ...prevRecordData,
                    firstName: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>

          <Box mt="3">
          <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Middle Name</FormLabel>
              <Input
                bg="white"
                variant="main"
                maxLength={200}
                placeholder="Middle Name"
                defaultValue={recordData.middleName}
                onChange={(e) => {
                  setRecordData(prevRecordData => ({
                    ...prevRecordData,
                    middleName: e.target.value,
                  }));
                }}
              />
            </FormControl>
            </Box>

            <Box mt="3">
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Last Name</FormLabel>
              <Input
                bg="white"
                variant="main"
                maxLength={200}
                placeholder="Last Name"
                defaultValue={recordData.lastName}
                onChange={(e) => {
                  setRecordData(prevRecordData => ({
                    ...prevRecordData,
                    lastName: e.target.value,
                  }));
                }}
              />
            </FormControl>
            </Box>


              <Box textAlign="left" mt="4" width="100%">
                <Button
                  variant="brand"
                  width="150px"
                  isLoading={submitLoading}
                  loadingText="Wait..."
                  onClick={handleSubmitRecord}
                >Proceed
                </Button>
              </Box>
      
          </DrawerBody>
        </DrawerContent>
      </Drawer>



    <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
        
    <Box width={{sm: "100%", md: "70%"}}>

     <Flex gap="4">
      <Box
        minH="150"
        width={{sm: "350px", md: "350px"}}
        borderRadius={"15"}
        borderTopWidth={"1px"}
        borderTopColor="brand.200"
        borderBottomWidth={"1px"}
        borderBottomColor="brand.200"
        borderLeftWidth={"1px"}
        borderLeftColor="gray.100"
        borderRightWidth={"1px"}
        borderRightColor="gray.200"
        backgroundImage={CardBg}
        backgroundSize={"cover"}
        backgroundPosition={"center"}
      >
        <Center width="100%" mt="6">
          <Box width="80%">
            <Text fontSize={{ sm: "lg", md: "2xl" }}>Academic Records</Text>
            <Heading size={{ sm: "md", md: "lg" }} color="brand.500">{recordStat.academicRecord}</Heading>
          </Box>
        </Center>
       </Box>


       <Box
        minH="150"
        width={{sm: "350px", md: "350px"}}
        borderRadius={"15"}
        borderTopWidth={"1px"}
        borderTopColor="brand.200"
        borderBottomWidth={"1px"}
        borderBottomColor="brand.200"
        borderLeftWidth={"1px"}
        borderLeftColor="gray.100"
        borderRightWidth={"1px"}
        borderRightColor="gray.200"
        backgroundImage={CardBg}
        backgroundSize={"cover"}
        backgroundPosition={"center"}
      >
        <Center width="100%" mt="6">
          <Box width="80%">
            <Text fontSize={{ sm: "lg", md: "2xl" }}>P2P Verified Records</Text>
            <Heading size={{ sm: "md", md: "lg" }} color="brand.500"> {recordStat.verifiedRecords} </Heading>
          </Box>
        </Center>
       </Box>
       </Flex>
     </Box>


     <Box minH="100" width={{sm: "100%", md: "70%"}} background="brand.500" p="5" borderRadius="5" mt="5"> 
        <Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}>If you graduated from the following Universities in the last 5 years, you can proceed commence the Peer 2 Peer Verification.</Text>
        <Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}  mt="3">Covenant University, Lagos State University, Obafemi Awolowo University, Olabisi Onabanjo University, University of Ibadan, University of Ilorin, University of Lagos</Text>
        <Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }} mt="3">If your school is not listed above, you can commence by listing your credentials here <Text as="span" color="#ffffff" textDecoration={"underline"} onClick={onDrawerOpen}>Submit Record</Text></Text>
    </Box>


     {
      !userState.kycVerified && 
      <Stack
          direction={{ base: 'column', md: 'row' }} // column for sm screens, row for md+ screens
          spacing="10"
          mt="10">
          <Box
            width={{ base: "100%", md: "70%" }}>
            <Box p="10" background="brand.100" borderRadius="10" minHeight={"400"}>
              <Heading size={{ sm: "sm", md: "md" }} color="#45005E">Are you ready to start a P2P Academic Record Verification?</Heading>
              <Text mt="5" fontSize={{ sm: "md", md: "lg" }} color="#45005E">Tell Us More About Yourself</Text>
              
              <SimpleGrid
                width="100%"
                columns={{ base: 1, md: 2 }}
                spacing="6"
                mb="20"
                mt="5">
                <Box>
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">First Name</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="First Name"
                      defaultValue={kycData.firstName}
                      onChange={(e) => {
                        setKycData(prevKycData => ({
                          ...prevKycData,
                          firstName: e.target.value,
                        }));
                      }}
                    />
                  </FormControl>
                </Box>

                <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Middle Name</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="Middle Name"
                      defaultValue={kycData.middleName}
                      onChange={(e) => {
                        setKycData(prevKycData => ({
                          ...prevKycData,
                          middleName: e.target.value,
                        }));
                      }}
                    />
                  </FormControl>


                  <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Last Name</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="Last Name"
                      defaultValue={kycData.lastName}
                      onChange={(e) => {
                        setKycData(prevKycData => ({
                          ...prevKycData,
                          lastName: e.target.value,
                        }));
                      }}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">NIN</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="NIN"
                      defaultValue={kycData.nin}
                      onChange={(e) => {
                        setKycData(prevKycData => ({
                          ...prevKycData,
                          nin: e.target.value,
                        }));
                      }}
                    />
                  </FormControl>

                <Box>
                  <FormLabel fontWeight="bold">&nbsp;</FormLabel>
                  <Button variant={"brand"} onClick={handleSubmitKYC} isLoading={loading} loadingText={"...Wait"}> Proceed </Button>
                </Box>
              </SimpleGrid>
            
            </Box>
          </Box>
        </Stack>
        }

     {
      userState.kycVerified && 
       <Card
          mt="10"
          background="brand.100"
          width={{ base: "100%", md: "50%" }}>
          <Box
             minHeight={"150"} pb="5">
            <Box borderRadius="10">
                <Heading size={{ sm: "sm", md: "md" }} color="#45005E" textAlign="center">Are you ready to start a P2P Verification of your Academic Record?</Heading>
                <Text mt="5" fontSize={{ sm: "md", md: "lg" }} color="#000000" textAlign="center">Let us help you to find your record; click on the Search button below </Text>
              </Box>
              <Box mt="4" p="2">
               <Link to={"/p2p/credentials/search"}>
                <Button variant={"brand"}> Search </Button>
              </Link>
              </Box>
          </Box>
        </Card>
     }



         <Box mt="10"><Heading size={{sm: "md", md: "lg"}}>Your Academic Records</Heading></Box>
         <SimpleGrid columns={{sm: 1, md: 2}} spacing="3">
         { 
          records && records.map((record)=>
          <Card background="brand.100"  boxShadow="md">
               <Box width="100%">

          <HStack>
            <Box width="80%"><Flex alignItems="center" mb={4}>
              <Text fontWeight="bold" fontSize={{sm: "md", md: "lg"}}>
              {record?.certificate.degree}, {record?.certificate.course} 
              </Text>
            </Flex>
            <Text color="gray.500" fontSize={{sm: "md", md: "lg"}} mb={4}>
            {record?.certificate?.institution?.institutionName }, {record?.certificate?.institution?.state }
            </Text></Box>
            <Box width="20%">
               <Flex><Heading textColor={"gray.500"}>{20 + 10 + record?.verificationScore}%</Heading>&nbsp;&nbsp;<Icon color="brand.500" mt="2" as="FiInfo" height="18" width="18" onClick={onBreakdownOpen} /></Flex>
            </Box>
         </HStack>
      {/* Verification Status */}
      <VStack spacing={2} align="stretch" mb={6}>
      <SimpleGrid spacing={2} mt="3" columns={{sm: 1, md: 2}}>
    <Box>
     <Tag colorScheme={"teal"}>Peer to Peer Verification Count {record?.verificationCount} </Tag>
     </Box> 
     <Box>
      <Tag colorScheme={"teal"}>Peer 2 Peer Verification Score {record?.verificationScore}</Tag> 
      </Box> 
   </SimpleGrid>
        <Text color="red.500" fontSize="sm">
          You can invite as many course mates as possible to verify your record, you can have a maximum peer to peer verification score of 20%
        </Text>
      </VStack>

      {/* Verification Link */}
      <Box bg="gray.200" p={3} borderRadius="md" mb={4}>
        <Link fontSize="sm" to={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`} isExternal>
          https://verify.akowe.app/p2p/credentials/invite/{record.certificate._id}
        </Link>
      </Box>

      <Flex gap="2" flexDirection="row" flexWrap="wrap">
        <FacebookShare url={shareUrl}
          quote={`I am inviting you to assist in verifying my record; ${record.certificate?.degree} in ${record.certificate?.course} at ${record.certificate?.institution?.institutionName}, ${record.certificate?.institution?.city}, ${record.certificate?.institution?.state} - Via Akowe.app 🔥`}
          hashtag={hashtags} />
        <TwitterShare
          url={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`}
          title={`I am inviting you to assist in verifying my record; ${record.certificate?.degree} in ${record.certificate?.course} at ${record.certificate?.institution?.institutionName}, ${record.certificate?.institution?.city}, ${record.certificate?.institution?.state} - Via Akowe.app 🔥`}
          hashtags={hashtags} />
        <LinkedinShare
          url={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`} />
        <WhatsappShare
          url={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`}
          title={`I am inviting you to assist in verifying my record; ${record.certificate?.degree} in ${record.certificate?.course} at ${record.certificate?.institution?.institutionName}, ${record.certificate?.institution?.city}, ${record.certificate?.institution?.state} - Via Akowe.app 🔥`}
          separator=":: "
        />
        <TelegramShare url={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`} />
        <EmailShare
          url={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`}
          subject={'I am inviting you to assist in verifying my academic record via Akowe.app'}
          body={`I am inviting you to assist in verifying my record; ${record.certificate?.degree} in ${record.certificate?.course} at ${record.certificate?.institution?.institutionName}, ${record.certificate?.institution?.city}, ${record.certificate?.institution?.state} - Via Akowe.app 🔥`}
        />
      </Flex>

      {/* Copy Link Button */}
      <Flex gap={2} mt="5">
      {/*<Button
        leftIcon={<CopyIcon />}
        colorScheme="brand"
        size="sm"
        onClick={() => navigator.clipboard.writeText("https://verify.akowe.app/p2p/credential/verify/"+record.inviteCode)}
      >
        Copy Link
      </Button>*/}
      <Button
        leftIcon={<EmailIcon />}
        colorScheme="brand"
        size="sm"
        onClick={() => { setVerifiableId(record._id); onOpen() } }
        >
        Send Invite
      </Button>
      </Flex>
    </Box>

          </Card>
          )}
       </SimpleGrid>

      {!records && <Box textAlign={"center"}> <Text fontSize={{sm: "sm", md: "md"}} color="gray.500">No record found</Text> </Box>}


       <Box mt="10"><Heading size={{sm: "md", md: "lg"}}>Received Verification Requests</Heading></Box>
       <SimpleGrid columns={{sm: 1, md:2}} mt="5" spacing={"2"}>
        {
         verifierRequests && verifierRequests.map((record) =>
          <Box p="10" background="brand.100" borderRadius="10" minHeight={"100"}>
            <Text mt="5" fontSize={{ sm: "md", md: "lg" }} color="#45005E">You have a request from <Text as="span" color="brand.500">{record.certificate.name} </Text> to assist in verifying their record; <Text as="span" color="brand.500"> {record.certificate?.degree}</Text> in <Text as="span" color="brand.500">{record.certificate?.course}</Text> at  <Text as="span" color="brand.500">{record.certificate?.institution?.institutionName}, {record.certificate?.institution?.city}, {record.certificate?.institution?.state}</Text> </Text> 
            
            {record.message && 
             <Text color="red.500" fontSize="sm">
               {record.message}
             </Text>
            }

           {record.verified?  <Button variant={"brand"} mt="5">Verified</Button>:
            <Button variant={"brand"} mt="5" onClick={()=> { 
              if(!record.accepted){
              setInviteId(record.inviteCode);
              onAcceptOpen();
              }else{
                handleGoToVerify(record.inviteCode);
              }
            }}> {record.accepted? "Verify Record": "Accept Request" } </Button>}

          </Box>)
         }
        </SimpleGrid>



       </Box>
    </>
  );
}

const LoadingHistory = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};


const SearchableSelect = ({ options, placeholder, onChange,  setRecordData }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    
    if (onChange) onChange(option.value);
  };

  return (
    <Box width="100%" position="relative">
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement="bottom"
        autoFocus={false}
      >
        <PopoverTrigger>
          <Box onClick={() => setIsOpen(!isOpen)} cursor="pointer">
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                variant="main"
                pl="10"
                maxLength={200}
                placeholder={placeholder || "Search..."}
                value={selectedOption ? selectedOption.label : searchQuery}
                onChange={(e) => {
                  setSelectedOption(null);
                  setSearchQuery(e.target.value);
                  if (!isOpen) setIsOpen(true);
                }}
                onClick={() => setIsOpen(true)}
                pr="2.5rem"
              />
              <Box
                position="absolute"
                right="8px"
                top="50%"
                transform="translateY(-50%)"
              >
                <ChevronDownIcon />
              </Box>
            </InputGroup>
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody p={0}>
            {filteredOptions.length > 0 ? (
              <List maxHeight="200px" overflowY="auto">
                {filteredOptions.map((option) => (
                  <ListItem
                    key={option.value}
                    px={4}
                    py={2}
                    onClick={() => handleSelect(option)}
                    _hover={{ bg: 'gray.100' }}
                    cursor="pointer"
                  >
                    {option.label}
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box p={4} textAlign="center">
                <Text color="gray.500">No options found</Text>
              </Box>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};