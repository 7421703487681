// Chakra Imports
import {
  Box,
  Breadcrumb,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import NavbarLinksP2P from "components/navbar/NavbarLinksP2P";

export default function NavbarUser(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, message, brandText } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{brandText}</title>
        <meta
          name="description"
          content="Apply for a virtual debit card and get it issued instantly with our online application process. Our service is secure and reliable, and you can start using your virtual card for online purchases and payments immediately."
        />
        <meta
          name="keywords"
          content="Virtual Card, virtual payment,virtual card,virtual credit card,virtual debit card,virtual visa card,virtual pay,virtual payment cards,virtual visa,generate a virtual credit card,instant virtual credit card,virtual pay card,virtual card for online payment,instant virtual card,mastercard virtual card,virtual mastercard,virtual bank card,virtual visa card instant,online virtual credit card,instant virtual debit card,generate virtual credit card,create virtual credit card"
        />
      </Helmet>
      <Box
        position={navbarPosition}
        zIndex="1000"
        backgroundPosition="center"
        backgroundSize='cover'
        transitionDelay="0s, 0s, 0s, 0s"
        transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
        transition-property="box-shadow, background-color, filter, border"
        transitionTimingFunction="linear, linear, linear, linear"
        alignItems={{ xl: "center" }}
        display={secondary ? "block" : "flex"}
        minH="75px"
        justifyContent={{ xl: "center" }}
        lineHeight="25.6px"
        mx="auto"
        mt={secondaryMargin}
        pb="8px"
        right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
        px={{
          sm: paddingX,
          md: "10px",
        }}
        ps={{
          xl: "12px",
        }}
        pt="8px"
        top={{ base: "12px", md: "16px", xl: "18px" }}
        w={{
          base: "calc(100vw - 6%)",
          md: "calc(100vw - 8%)",
          lg: "calc(100vw - 6%)",
          xl: "calc(100vw - 350px)",
          "2xl": "calc(100vw - 365px)",
        }}
      >
        <Flex
          w="100%"
          flexDirection={{
            sm: "column",
            md: "row",
          }}
          alignItems={{ xl: "center" }}
          mb={gap}
        >
          <Box mb={{ sm: "8px", md: "0px" }}>
            <Breadcrumb>
              {/*<BreadcrumbItem color={secondaryText} fontSize='sm' mb='5px'>
              <BreadcrumbLink href='#' color={secondaryText}>
                Pages
              </BreadcrumbLink>
            </BreadcrumbItem>*

            <BreadcrumbItem color={secondaryText} fontSize='sm'>
              <BreadcrumbLink href='#' color={secondaryText}>
                {brandText}
              </BreadcrumbLink>
             </BreadcrumbItem>*/}
            </Breadcrumb>
            {/* Here we create navbar brand, based on route name */}
            {/*<Link
              color={mainText}
              href="#"
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              fontFamily="ManropeSemiBold"
              fontSize={{ base: "md", sm: "md", md: "lg", lg: "xl" }}
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              {brandText}
            </Link>*/}
          </Box>
          <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
            <NavbarLinksP2P
              onOpen={props.onOpen}
              logoText={props.logoText}
              secondary={props.secondary}
              fixed={props.fixed}
              scrolled={scrolled}
            />
          </Box>
        </Flex>
        {secondary ? <Text color="white">{message}</Text> : null}
      </Box>
    </>
  );
}

NavbarUser.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
