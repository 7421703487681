import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Flex,
  Box,
  Progress,
  Heading,
  Text,
  Icon,
  Stat,
  StatLabel,
  StatNumber,
  SimpleGrid,
  Divider,
  Container,
} from "@chakra-ui/react";
import { GetPublicRecordsStat } from "services/certificateService";
import { ArrowBackIcon } from "@chakra-ui/icons";

function Index() {
  const [loading, setLoading] = useState(false);
  const [recordStat, setRecordStat] = useState({
    numberOfInstitutions: 0,
    numberOfRecords: 0,
    numberOfVerifications: 0,
    institutions: [],
  });
  const getPublicRecordsStat = async () => {
    try {
      setLoading(true);
      const response = await GetPublicRecordsStat();
      setLoading(false);
      if (response.success) {
        setRecordStat(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPublicRecordsStat();
  }, []);

  return (
    <>
      <Helmet>
        <title>P2P | Public Record's Data</title>
        <meta name="description" content="Akowe P2P - Public Record's Data" />
        <meta name="keywords" content="" />
      </Helmet>

      <Box bg="brand.500" minH="100vh" color="white" py={10}>
        <Container maxW="5xl">
          {/* Header Section */}
          <Box>
            <Link to="/p2p">
              <Text fontSize={{ sm: "md", md: "lg" }}>
                <Icon as={ArrowBackIcon} /> Peer 2 Peer Portal
              </Text>
            </Link>
          </Box>
          <Heading textAlign="center">The State of Academic Records</Heading>
          <Text
            textAlign="center"
            mt={4}
            fontSize={{ sm: "md", md: "lg" }}
            color="gray.200"
          >
            The Akowe Status Page provides a comprehensive overview of the
            platform’s impact and reach within the educational ecosystem...
          </Text>

          {/* Statistics Section */}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={10}>
            <StatBox
              label="Public Academic Records from"
              value={recordStat.numberOfInstitutions}
              label2={"Tertiary Institutions"}
            />
            <StatBox
              label="Number of Aggregated Public Academic Records"
              value={recordStat.numberOfRecords}
              label2={"Records"}
            />
          </SimpleGrid>

          {/* Public Academic Records Table */}
          <Box bg="white" color="black" p={5} borderRadius="lg" mt={8}>
            <Heading size="md" mb={3}>
              Public Academic Records from:
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              {recordStat.institutions.map((institution) => (
                <RecordItem
                  label={institution.institutionName}
                  value={institution.recordCount}
                />
              ))}
            </SimpleGrid>
          </Box>

          {/* Verification Stats */}
          <Box mt={8} width="100%">
            <VerificationProgress
              label="Academic Records Verified by Peers"
              value={
                (recordStat.numberOfVerifications * 100) /
                recordStat.numberOfRecords
              }
              total={recordStat.numberOfRecords}
              count={recordStat.numberOfVerifications}
              color="blue"
            />
          </Box>

          {/*
          <Box mt={8} width="100%">
          <VerificationProgress label="Academic Records Verified by NIN" value={8} total="494,999" count="9,199" color="gray" />
        </Box>*/}
        </Container>
      </Box>
    </>
  );
}
function StatBox({ label, label2, value, highlight }) {
  return (
    <Box
      bg={highlight ? "purple.600" : "teal.800"}
      p={5}
      borderRadius="lg"
      textAlign="center"
    >
      <Stat>
        <StatLabel fontSize="sm">{label}</StatLabel>
        <StatNumber fontSize="lg">
          {new Intl.NumberFormat("en-US").format(value)} {label2}
        </StatNumber>
      </Stat>
    </Box>
  );
}

function RecordItem({ label, value }) {
  return (
    <Flex justify="space-between" p={2} bg="gray.100" borderRadius="md">
      <Text fontWeight="bold" fontSize={{ sm: "md", md: "xl" }}>
        {label}:
      </Text>
      <Text fontSize={{ sm: "md", md: "xl" }}>
        {new Intl.NumberFormat("en-US").format(value)} Records
      </Text>
    </Flex>
  );
}

function VerificationProgress({ label, value, total, count, color }) {
  return (
    <Box my={5} width="100%">
      <Flex justify="space-between" mb={2}>
        <Text>{label}:</Text>
        <Text color="white" fontSize={{ sm: "lg", md: "xl" }}>
          {value}%
        </Text>
      </Flex>
      <Progress
        value={value}
        colorScheme={color}
        size="lg"
        width="100%"
        height="8"
        borderRadius="lg"
      />
      <Flex justify="space-between" mb={2}>
        <Text fontSize={{ sm: "lg", md: "xl" }}>
          {new Intl.NumberFormat("en-US").format(count)}
        </Text>
        <Text color="white" fontWeight="bold" fontSize={{ sm: "lg", md: "xl" }}>
          {new Intl.NumberFormat("en-US").format(total)}
        </Text>
      </Flex>
    </Box>
  );
}

export default Index;
