import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Image,
  useToast
} from "@chakra-ui/react";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// Custom components
import DefaultAuth from "layouts/auth/Default";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import illustrationImage from "assets/images/login1.png";
import Logo from "assets/images/logo.svg";
import { UpdatePassword } from "services/userService";


function Index() {
  //const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { code } = useParams();
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object({
    password: Yup
      .string()
      .min(6)
      .matches(
        /^(?=.*[a-z])/,
        "Must contain at least one lowercase character"
      )
      .matches(
        /^(?=.*[A-Z])/,
        "Must contain at least one uppercase character"
      )
      .matches(/^(?=.*[0-9])/, "Must contain at least one number")
      .matches(
        /^(?=.*[!@#%&])/,
        "Must contain at least one special character ?=.*!@#%&"
      )
      .required()
      .label("Password"),
    confirmPassword: Yup
      .string()
      .required()
      .label("Confirm Password")
      .oneOf([Yup.ref("password")], "Password doesn't corresponds")
  });

  const handleUpdatePassword = async (userr, setSubmitting) => {
    try {
      setSubmitting(true);
      const user = { password: userr.password, code: code };
      const response = await UpdatePassword(user);
      setSubmitting(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "Password changed successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        navigate("/p2pauth/login");
        return;
      }
    } catch (error) {
      setSubmitting(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Password Reset</title>
        <meta name="description" content="Change Password" />
        <meta name="keywords" content="" />
      </Helmet>

      <DefaultAuth illustrationImage={illustrationImage} heading="Verification of Academic Records">
        <Flex width={"100%"} justify={"center"}>
          <Stack
            spacing={4}
            mx={"auto"}
            w={{ base: "95%", sm: "95%", md: "60%", lg: "50%" }}
          >
            <Stack p={2} align={"left"}>
              <Image src={Logo} width="70px" height="auto" />
              <Heading fontFamily="ManropeSemiBold" fontWeight={"bold"} size={{ sm: "sm", md: "md" }} textAlign={"left"} color="#45005E">
                Update Password
              </Heading>
            </Stack>
            <Box p={2} width="100%">
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: ""
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleUpdatePassword(values, setSubmitting);
                }}
              >
                {({ handleSubmit, handleChange, handleBlur, values, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Stack spacing={4} width="100%">
                      <FormControl id="password" isRequired>
                        <FormLabel fontFamily="ManropeSemiBold">Password</FormLabel>
                        <InputGroup>
                          <Input
                            type={showPassword ? "text" : "password"}
                            variant="main"
                            maxLength={100}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Enter Password"
                          />
                          <InputRightElement h={"full"}>
                            <Button
                              variant={"ghost"}
                              onClick={() =>
                                setShowPassword((showPassword) => !showPassword)
                              }
                            >
                              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                      </FormControl>

                      <FormControl id="confirmPassword" isRequired>
                        <FormLabel fontFamily="ManropeSemiBold">Confirm Password</FormLabel>
                        <InputGroup>
                          <Input
                            type={showPassword ? "text" : "password"}
                            variant="main"
                            maxLength={100}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            placeholder="Re-enter Password"
                          />
                          <InputRightElement h={"full"}>
                            <Button
                              variant={"ghost"}
                              onClick={() =>
                                setShowPassword((showPassword) => !showPassword)
                              }
                            >
                              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <ErrorMessage name="confirmPassword" component="div" style={{ color: 'red' }} />
                      </FormControl>
                    </Stack>

                    <Stack spacing={3} mt={4}>
                      <Button
                        variant="brand"
                        isLoading={isSubmitting}
                        loadingText="Wait"
                        mb="10px"
                        type="submit"
                      >
                        Update Password
                      </Button>

                    </Stack>
                  </Form>
                )}
              </Formik>
            </Box>
          </Stack>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default Index;
