import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import {
  Box,
  HStack,
  VStack,
  Flex,
  Stack,
  Button,
  Text,
  Radio,
  RadioGroup,
  FormLabel,
  Select,
  Avatar,
  Skeleton,
  SkeletonText,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { GetCertificateByInviteCode, VerifyRecord } from "services/certificateService";

export default function Search() {
  const { inviteCode } = useParams();
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const [verifyData, setVerifyData] = useState({
    identify: "",
    gradYear: "",
    /*person1: "",
    person2: "",*/
    relationship: "",
    inviteCode
  });

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [record, setRecord] = useState(null);

  const getCertificateByInviteCode = async () => {
    try {
      setLoading(true);
      const response = await GetCertificateByInviteCode(inviteCode, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setRecord(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  }

  const handleVerifyRecord = async () => {
    try {
      setSubmitLoading(true);
      const response = await VerifyRecord(verifyData, token);
      setSubmitLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setSubmitLoading(false);
    }
  }

  useEffect(() => {
    getCertificateByInviteCode();
  }, []);

  return (
    <> 
     <Box width={{sm: "100%", md: "50%"}} p={4} mt="5">
      <Flex align="center" gap={2} mb={6}>
        <Text fontSize={{ sm: "md", md: "lg" }} color="brand.500">Verify - </Text>
        <Text fontSize={{ sm: "md", md: "lg" }}>{record && record.name}'s Record</Text>
      </Flex>

      <Box background="brand.100"  p={6} borderRadius="xl" mb={6}>
        <VStack spacing={2} align="center">
          <Avatar size="lg" />
          <Text fontWeight="medium" color="brand.500">{record && (record.degree+", "+record.course)}</Text>
          <Text color="gray.600">{record && (record.institution.institutionName+", "+record.institution.city+", "+record.institution.state)}</Text>
        </VStack>
      </Box>

      <Text mb={2} fontSize={{ sm: "md", md: "lg" }} fontWeight="bold" color="#45005E">Answer the questions below to complete the verification</Text>

      <VStack spacing={6} align="stretch" mt="5">
        <Box>
          <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Did you know this person when you were in school?</FormLabel>
          <RadioGroup variant="main"
          >
            <HStack spacing={4}>
              <Radio value="yes" variant="main" onChange={(e) => {
            setVerifyData(prevVerifyData => ({
              ...prevVerifyData,
               identify: "yes"
            }));
          }}>Yes</Radio>
              <Radio value="no" variant="main" onChange={(e) => {
            setVerifyData(prevVerifyData => ({
              ...prevVerifyData,
               identify: "no"
            }));
          }}>No</Radio>
            </HStack>
          </RadioGroup>
        </Box>

        <Box>
          <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Did {record && record.name}' graduate in the year {record && record.validYear} ?</FormLabel>
          <RadioGroup variant="main"
          >
            <HStack spacing={4}>
              <Radio value="yes" variant="main" onChange={(e) => {
            setVerifyData(prevVerifyData => ({
              ...prevVerifyData,
               gradYear: "yes"
            }));
          }}>Yes</Radio>
              <Radio value="no" variant="main" onChange={(e) => {
            setVerifyData(prevVerifyData => ({
              ...prevVerifyData,
              gradYear: "no"
            }));
          }}>No</Radio>
            </HStack>
          </RadioGroup>
        </Box>

        <Box>
          <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">What was your relationship to this person?</FormLabel>
          <Select  bg="white"
                      variant="main" placeholder="Select relationship"
                      onChange={(e) => {
                        setVerifyData(prevVerifyData => ({
                          ...prevVerifyData,
                          relationship: e.target.value
                        }));
                      }}
                      >
            <option value="course">Course Mate</option>
            <option value="faculty">Faculty Mate</option>
          </Select>
        </Box>

        {/*<Box>
          <Text mb={3} fontWeight="medium">Do you know what State they served for NYSC?</Text>
          <RadioGroup mb={4}>
            <HStack spacing={4}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </HStack>
          </RadioGroup>

          <Text mb={3} fontWeight="medium">If Yes, What State did they serve for NYSC?</Text>
          <Select placeholder="Select State">
            <option value="lagos">Lagos</option>
            <option value="abuja">Abuja</option>
            <option value="rivers">Rivers</option>
          </Select>
        </Box>
        */}

        {/* <Box>
          <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Can you name any 2 persons you graduated with?</FormLabel>
          <VStack spacing={4}>
            <Box w="100%">
              <Text mb={2} fontSize="sm">Person 1</Text>
              <Input 
              variant="main"
              maxLength={200}
                placeholder="Enter First Name & Last Name"
                size="md"
                bg="white"
                onChange={(e) => {
                  setVerifyData(prevVerifyData => ({
                    ...prevVerifyData,
                    person1: e.target.value
                  }));
                }}
              />
            </Box>
            
            <Box w="100%">
              <Text mb={2} fontSize="sm">Person 2</Text>
              <Input 
              variant="main"
              maxLength={200}
                placeholder="Enter First Name & Last Name"
                size="md"
                bg="white"
                onChange={(e) => {
                  setVerifyData(prevVerifyData => ({
                    ...prevVerifyData,
                    person2: e.target.value
                  }));
                }}
              />
            </Box>
          </VStack>
        </Box> */}

        <Box mb="10">
           <FormLabel fontWeight="bold">&nbsp;</FormLabel>
              <Button variant={"brand"} onClick={handleVerifyRecord} isLoading={loading || submitLoading} loadingText={"...Wait"}> Submit </Button>
          </Box>
        
      </VStack>
    </Box>
    </>
  );
}

const LoadingHistory = () => {
  return (
    <>
      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};