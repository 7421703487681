import React from "react";
import "assets/css/App.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import AppLayout from "layouts/app";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/webauth";
import P2PLayout from "layouts/p2p";
import P2PAuthLayout from "layouts/p2pauth";

import routesApp from "routesApp.js";
import routesAdmin from "routesAdmin.js";
import routesP2P from "routesP2P.js";

import P2PLogin from "views/p2p/auth/login";
import P2PRegister from "views/p2p/auth/register";
import P2PVerify from "views/p2p/auth/verify";
import P2PVerifyEmail from "views/p2p/auth/verifyEmail";
import P2PTwoFactor from "views/p2p/auth/twofactor";
import P2PForgot from "views/p2p/auth/forgot";
import P2PUpdatePassword from "views/p2p/auth/updatepassword";
import P2POtp from "views/p2p/auth/otp";

import PublicData from "views/public/data";

import Login from "views/auth/login";
import Register from "views/auth/register";
import Verify from "views/auth/verify";
import VerifyEmail from "views/auth/verifyEmail";
import TwoFactor from "views/auth/twofactor";
import Forgot from "views/auth/forgot";
import UpdatePassword from "views/auth/updatepassword";
import Otp from "views/auth/otp";

function App() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if ((prop.layout === "/app") && !prop.collapse) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if ((prop.layout === "/app") && prop.collapse) {
        return (
          <Route
            path={prop.path + "/*"}
            element={prop.component}
            key={key}
          >
            <Route path="*" element={<Navigate to={prop.layout + "/" + prop.path + "/index"} replace />} />
            {getRoutes(prop.items)}
          </Route>
        );
      }

      if ((prop.layout === "/admin") && !prop.collapse) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if ((prop.layout === "/admin") && prop.collapse) {
        return (
          <Route
            path={prop.path + "/*"}
            element={prop.component}
            key={key}
          >
            <Route path="*" element={<Navigate to={prop.layout + "/" + prop.path + "/index"} replace />} />
            {getRoutes(prop.items)}
          </Route>
        );
      }

      if ((prop.layout === "/p2p") && !prop.collapse) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if ((prop.layout === "/p2p") && prop.collapse) {
        return (
          <Route
            path={prop.path + "/*"}
            element={prop.component}
            key={key}
          >
            <Route path="*" element={<Navigate to={prop.layout + "/" + prop.path + "/index"} replace />} />
            {getRoutes(prop.items)}
          </Route>
        );
      }

      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  const AuthRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/auth/login" replace />} />
        <Route path="/auth/*" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="signup" element={<Register />} />
          <Route path="verify/:code" element={<Verify />} />
          <Route path="changepassword/:code" element={<UpdatePassword />} />
          <Route path="twofactor/:id" element={<TwoFactor />} />
          <Route path="verifyemail" element={<VerifyEmail />} />
          <Route path="otp" element={<Otp />} />
          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Route>
      </Routes>
    );
  };

   const P2PAuthRoutes = () => {
    return (
      <Routes>
        <Route path="/p2pauth" element={<Navigate to="/p2pauth/signup" replace />} />
        <Route path="/p2pauth/*" element={<P2PAuthLayout />}>
          <Route path="login" element={<P2PLogin />} />
          <Route path="forgot" element={<P2PForgot />} />
          <Route path="signup" element={<P2PRegister />} />
          <Route path="verify/:code" element={<P2PVerify />} />
          <Route path="changepassword/:code" element={<P2PUpdatePassword />} />
          <Route path="twofactor/:id" element={<P2PTwoFactor />} />
          <Route path="verifyemail" element={<P2PVerifyEmail />} />
          <Route path="otp" element={<P2POtp />} />
        </Route>
        <Route path="/public/*" element={<P2PAuthLayout />}>
          <Route path="data" element={<PublicData />} />
        </Route>
      </Routes>
    );
  };

  const AppRoutes = () => {
    return (
      <Routes>
        <Route path="/app/*" element={<AppLayout />}>
          {getRoutes(routesApp)}
        </Route>
      </Routes>
    );
  };

  const P2PRoutes = () => {
    return (
      <Routes>
        <Route path="/p2p" element={<Navigate to="/p2pauth/signup" replace />} />
        <Route path="/p2p/data" element={<Navigate to="/public/data" replace />} />
        <Route path="/p2p/*" element={<P2PLayout />}>
          {getRoutes(routesP2P)}
        </Route>
      </Routes>
    );
  };


  const AdminRoutes = () => {
    return (
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />}>
          {getRoutes(routesAdmin)}
        </Route>
      </Routes>
    );
  };

  return (
    <BrowserRouter>
      <AuthRoutes />
      <P2PRoutes />
      <P2PAuthRoutes />
      <AppRoutes />
      <AdminRoutes />
    </BrowserRouter>
  );
}

export default App;
