import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Center,
  Stack,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Skeleton,
  SkeletonText,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import Card from "components/card/Card";
import CardBg from "assets/images/ribbon.svg";
import {  GetCertificate, AcceptVerificationInviteByCertificateId } from "services/certificateService";

export default function Search() {
  const { isOpen: isAcceptOpen, onOpen: onAcceptOpen, onClose: onAcceptClose } = useDisclosure();
  const navigate = useNavigate();
  const { certificateId } = useParams();
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const [loading, setLoading] = useState(false);
  const [acceptInviteLoading, setAcceptInviteLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [record, setRecord] = useState(null);

  const getCertificateById = async () => {
    try {
      setLoading(true);
      const response = await GetCertificate(certificateId, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setRecord(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  }

  const handleAcceptInvite = async () => {
    try {

      if(!userState.kycVerified){
        toast({
          title: "Please complete KYC first",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        navigate("/p2p/dashboard");
        return;
      }
      setAcceptInviteLoading(true);
      const response = await AcceptVerificationInviteByCertificateId(certificateId, token);
      setAcceptInviteLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: "Verification invite has been accepted",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onAcceptClose();
        navigate("/p2p/dashboard");
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setAcceptInviteLoading(false);
    }
}

  useEffect(() => {
    getCertificateById();
  }, []);

  return (
    <> 
       <Modal onClose={onAcceptClose} size={"2xl"} isOpen={isAcceptOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Accept Verification Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              width="100%"
            > 
              <Text size={{ sm: "md", md: "lg" }} textAlign={"center"}>Click on Proceed to accept invite to verify candidate record</Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button  mb="5" variant="brand" isLoading={acceptInviteLoading} loadingText="Wait..." onClick={handleAcceptInvite}>Proceed</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


     <Box width={{sm: "100%", md: "100%"}} p={4} mt="5">

         <Center width={{sm: "100%", md: "100%"}} minHeight="400">
           {
              loading && <LoadingHistory /> 
            }

            {
              !loading &&  record &&
                 <Box p="10" width={{sm: "100%", md: "50%"}} background="brand.100" borderRadius="10" minHeight={"100"}>
                    <Text mt="5" fontSize={{ sm: "md", md: "lg" }} color="#45005E">You have a request from <Text as="span" color="brand.500">{record?.name} </Text> to assit in verifying their record; <Text as="span" color="brand.500"> {record?.degree}</Text> in <Text as="span" color="brand.500">{record?.course}</Text> at  <Text as="span" color="brand.500">{record?.institution?.institutionName}, {record?.institution?.city}, {record?.institution?.state}</Text> </Text> 
                    <Button variant={"brand"} mt="5" onClick={()=> {
                      onAcceptOpen();
                    }}> Accept Request </Button>
              </Box>
          }
          </Center>
    </Box>
    </>
  );
}

const LoadingHistory = () => {
  return (
    <>
      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};