import axiosInstance from "./axiosInstance";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetTransaction = async (queryString, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/transactions?" + queryString,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetCollections = async (token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/transactions/collections",
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetDashboardSummary = async (filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/transactions/stat",
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetPast30DaysSummary = async (filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/transactions/chart",
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  GetTransaction,
  GetCollections,
  GetDashboardSummary,
  GetPast30DaysSummary,
};
