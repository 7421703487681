import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Text,
  Tag,
  Center,
  Button,
  Heading,
  Image,
  VStack,
  Input,
  Flex,
  Stack,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Skeleton,
  SkeletonText,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { GetInstitution } from "services/institutionService";
import { GetCountries } from "services/miscService";
import { SearchRecord, AddRecord } from "services/certificateService";
import { FaUniversity, FaUser } from "react-icons/fa";
import HappyFace from "assets/images/happyface.png"

const { DateTime } = require("luxon");

export default function Search() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchData, setSearchData] = useState({
    firstName: userState.firstName? userState.firstName: "",
    lastName: userState.lastName? userState.lastName: "",
    middleName: userState.middleName? userState.middleName: "",
    country: "",
    institution: "",
    year: ""
  });

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1999 }, (_, i) => i + 2000);

  const [countries, setCountries] = useState([]);
  const [institutions, setIntitutions] = useState([]);

  const [record, setRecord] = useState(null);
  const [records, setRecords] = useState([]);
  const [recordId, setRecordId] = useState("");
  const [showYearOption, setShowYearOption] = useState(false);
  const [gradYear, setGradYear] = useState(null);

  const [pdf, setPdf] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [addRecordLoading, setAddRecordLoading] = useState(false);
  const [credentialId, setCredentialId] = useState("");
  

  const getInstitutions = async () => {
    try {
      setLoading(true);
      const response = await GetInstitution(token);
      setLoading(false);
      if (response.success) {
        setIntitutions(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getCountries = async () => {
    try {
      setLoading(true);
      const response = await GetCountries(token);
      setLoading(false);
      if (response.success) {
        setCountries(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    try {
      setSubmitLoading(true);
      const response = await SearchRecord(searchData, token);
      setSubmitLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setRecords(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setSubmitLoading(false);
    }
  }

  const handleAddRecord = async () => {
    try {
      setAddRecordLoading(true);
      const verifiableData = {
       credentialId, gradYear
      }
      const response = await AddRecord(verifiableData, token);
      setAddRecordLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setAddRecordLoading(false);
    }
  }

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromISO(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_MED);
  };

  useEffect(() => {
    getInstitutions();
    getCountries();
  }, []);

  return (
    <> 
       
       <Modal onClose={onClose} size={"xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to add this record to your profile?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {record &&  <Card mt="5" width="100%" background="brand.100">
      
                <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}><Icon as={FaUser} width="18px" height="18px" />&nbsp; Candidate's Name</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.name}</Text></Flex></Box>
                <Box width="100%" mt="2"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}><Icon as={FaUniversity} width="18px" height="18px" />&nbsp; Institution</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.institutionName}</Text></Flex></Box>
                {/*<Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Faculty</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.faculty ? record?.faculty : "N/A"}</Text></Flex></Box>*/}
                <Box width="100%" mt="2"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}>Course</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.course ? record.course : "N/A"}</Text></Flex></Box>
                <Box width="100%" mt="2"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}>Degree</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.degree ? record.degree : "N/A"}</Text></Flex></Box>
                <Box width="100%" mt="2"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}>Grade</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.grade ? record.grade : "N/A"}</Text></Flex></Box>
                <Box width="100%" mt="2"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.validYear ? record.validYear : "N/A"}</Text>&nbsp; <Text textDecoration={"underline"} onClick={()=>{
                  setRecordId(record._id);
                  setShowYearOption(true);
                }}>Change</Text></Flex></Box>

                {
                 recordId===record._id && showYearOption &&
                <Box width="100%" mt="4">
                
                <FormControl isRequired>
                <FormLabel fontWeight="bold">Change Graduation Year</FormLabel>
                <Select
                  bg="white"
                  variant="main"
                  placeholder="Select Year"
                  defaultValue={record.validYear ? record.validYear : ""}
                  onChange={(e) => {
                    setGradYear(e.target.value);
                  }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
                <FormHelperText textColor={"red.400"}>Change your graduation year if incorrect</FormHelperText>
              </FormControl>
              </Box>
               }
              </Card>
            } 

          </ModalBody>
          <ModalFooter>
            <Button variant="brand" isLoading={addRecordLoading} loadingText="..Wait" onClick={handleAddRecord}>Add Record</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
    <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
    <Box width={{sm: "100%", md: "100%", lg: "50%"}}>
      <Center mb="2">
        <Image
          src="https://em-content.zobj.net/thumbs/120/apple/354/slightly-smiling-face_1f642.png"
          alt="Smiley face"
          boxSize="50px"
        />
      </Center>
        <Heading as="h3" size="md" textAlign="center">
          Let us help you find your record, Kindly fill in your name while in school
        </Heading>
        <Text fontSize={{sm: "md", md: "lg"}} mt="3" textAlign="center">
           KYC Status:{" "}
          <Text as="span" color="green.500">
            Verified Successfully
          </Text>
        </Text>
        {/*<Text fontSize="sm" color="red.500" textAlign="center">
          Kindly ensure that all information provided is accurate and legitimate.
        </Text>*/}
      </Box>

      <Box width="100%" mt="10">

     <SimpleGrid columns={{md:1, lg: 2}} spacing={{md: 5, lg: 10}}>
      {/* Form */}
      <VStack as="form" spacing={6} mt={10}>
        {/* Firstname */}
        <FormControl isRequired>
          <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">First Name</FormLabel>
          <Input
            bg="white"
            variant="main"
            maxLength={200}
            placeholder="First Name"
            defaultValue={searchData.firstName}
            onChange={(e) => {
              setSearchData(prevSearchData => ({
                ...prevSearchData,
                firstName: e.target.value,
              }));
            }}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Middle Name</FormLabel>
          <Input
            bg="white"
            variant="main"
            maxLength={200}
            placeholder="Enter Middlename"
            defaultValue={searchData.middleName}
            onChange={(e) => {
              setSearchData(prevSearchData => ({
                ...prevSearchData,
                middleName: e.target.value,
              }));
            }}
          />
        </FormControl>


        <FormControl isRequired>
          <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Last Name</FormLabel>
          <Input
            bg="white"
            variant="main"
            maxLength={200}
            placeholder="Last Name"
            defaultValue={searchData.lastName}
            onChange={(e) => {
              setSearchData(prevSearchData => ({
                ...prevSearchData,
                lastName: e.target.value,
              }));
            }}
          />
        </FormControl>

     
        <SimpleGrid
                width="100%"
                columns={{ base: 1, md: 2 }}
                spacing="6"
                mb="15">
                <Box>
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">Country</FormLabel>
                    <Select
                      bg="white"
                      variant="main"
                      placeholder="Select Country"
                      value={searchData.country}
                      onChange={(e) => {
                        setSearchData(prevSearchData => ({
                          ...prevSearchData,
                          country: e.target.value
                        }));
                      }}
                    >
                      {
                        countries.length > 0 &&
                        countries.map((country) => (
                          <option key={country._id} value={country._id}>
                            {country.countryName}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">School/Institution</FormLabel>
                    <Select
                      bg="white"
                      variant="main"
                      placeholder="Select School/Instituition"
                      value={searchData.institution}
                      onChange={(e) => {
                        setSearchData(prevSearchData => ({
                          ...prevSearchData,
                          institution: e.target.value
                        }));
                      }}
                    >
                      {
                        institutions.length > 0 &&
                        institutions.map((institution) => (
                          <option key={institution._id} value={institution._id}>
                            {institution.institutionName}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </SimpleGrid>

             
              {/*<FormControl isRequired>
                <FormLabel fontWeight="bold">Year</FormLabel>
                <Select
                  bg="white"
                  variant="main"
                  placeholder="Select Year"
                  value={searchData.year}
                  onChange={(e) => {
                    setSearchData(prevSearchData => ({
                      ...prevSearchData,
                      year: e.target.value
                    }));
                  }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </FormControl> */}

                 <Box  width="100%" >
                  <Button  width="100%" variant={"brand"} onClick={handleSearch} isLoading={submitLoading} loadingText={"...Wait"}>Search </Button>
                </Box>
      </VStack>
      <Box>




      {
              loading && <LoadingHistory /> 
            }

            {
              records.length>0 && (<><Box width={{md: "100%", lg: "70%"}} mt="10"> 
              <Center width="100%">
                <Image src={HappyFace} width="70" height="70" />
              </Center>
            </Box>
    
           <Box width={{md: "100%", lg: "70%"}}> 
                <Heading size={{ sm: "sm", md: "md" }} color="brand.500" textAlign={"center"}>Academic Record Found</Heading>
            </Box></>)
            }

            {
              !loading && records.map((record) => 

              <Card mt="5" width={{md: "100%", lg: "70%"}} background="brand.100">
      
                <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}><Icon as={FaUser} width="18px" height="18px" />&nbsp; Candidate's Name</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.name}</Text></Flex></Box>
                <Box width="100%" mt="2"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}><Icon as={FaUniversity} width="18px" height="18px" />&nbsp; Institution</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.institutionName}</Text></Flex></Box>
                {/*<Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Faculty</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.faculty ? record?.faculty : "N/A"}</Text></Flex></Box>*/}
                <Box width="100%" mt="2"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}>Course</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.course ? record.course : "N/A"}</Text></Flex></Box>
                <Box width="100%" mt="2"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}>Degree</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.degree ? record.degree : "N/A"}</Text></Flex></Box>
                <Box width="100%" mt="2"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}>Grade</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.grade ? record.grade : "N/A"}</Text></Flex></Box>
                <Box width="100%" mt="2"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.validYear ? record.validYear : "N/A"}</Text></Flex></Box>

    

                {record.affliatedInstitution && <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "sm" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.affliatedInstitution ? record.affliatedInstitution : "N/A"}</Text></Flex></Box>}
                  <Box  width="100%" mt="5">
                    <Button variant={"brand"} width="100%" onClick={()=>{
                      setRecord(record);
                      setCredentialId(record._id)
                      onOpen()
                      }}> Add Record to Profile </Button>
                </Box>
              </Card>)
            }

      </Box>
      </SimpleGrid>

           </Box>
       </Box>
    </>
  );
}

const LoadingHistory = () => {
  return (
    <>
      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};