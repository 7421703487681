import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  Center,
  VStack,
  SimpleGrid,
  Heading,
  Icon,
  Text,
  Button,
  Avatar,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import {
  GetUser, UpdateUserByID, DeleteUser
} from "services/userService";
import { FiArrowLeft, FiUser, FiPhone } from "react-icons/fi";
import { FaEnvelope, FaWhatsapp } from "react-icons/fa";

export default function Roles() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSuspendOpen, onOpen: onSuspendOpen, onClose: onSuspendClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const privileges = userState.hasOwnProperty("privileges")
    ? userState.privileges
    : [];
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);

  const getUser = async () => {
    try {
      setLoading(true);
      const response = await GetUser(token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setUser(response.data);
        setUserData({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          phone: response.data.phone
        });
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleUpdateUser = async () => {
    try {
      setLoading(true);
      const response = await UpdateUserByID(userData, user._id, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      getUser();
      onClose();
      onSuspendClose();
      onDrawerClose();
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      const response = await DeleteUser(user._id, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "User deleted successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to delete your account?.
                </Heading>
                <Text mt="2" fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}>This action is not reversible!!!</Text>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='primary' mr={3} onClick={onClose}>
              No
            </Button>
            <Button
              variant="brand"
              background="#000000"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                deleteUser();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isSuspendOpen} onClose={onSuspendClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onSuspendClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  {user !== null && user.suspended ? "Are you sure you want to activate your account" : "Are you sure you want to suspend your account?"}
                </Heading>
                <Text fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}><br />{user !== null && user.suspended ? "You can de-activate your account anytime" : "You can activate your account anytime"}</Text>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onSuspendClose}>
              No
            </Button>
            <Button
              variant="primary"
              background="#000000"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                handleUpdateUser();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit Profile</DrawerHeader>

          <DrawerBody>
            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">First Name</FormLabel>
                <Input
                  variant="main"
                  maxLength={50}
                  placeholder="First Name"
                  defaultValue={userData && userData.firstName}
                  onChange={(e) => {
                    setUserData(prevUser => ({
                      ...prevUser,
                      firstName: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Last Name</FormLabel>
                <Input
                  variant="main"
                  maxLength={50}
                  type="text"
                  placeholder="Last Name"
                  defaultValue={userData && userData.lastName}
                  onChange={(e) => {
                    setUserData(prevUser => ({
                      ...prevUser,
                      lastName: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Phone</FormLabel>
                <Input
                  variant="main"
                  maxLength={50}
                  type="tel"
                  placeholder="Phone"
                  defaultValue={userData && userData.phone}
                  onChange={(e) => {
                    setUserData(prevUser => ({
                      ...prevUser,
                      phone: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box textAlign="left" mt="5" width="100%">
              <Button
                variant="brand"
                width="150px"
                isLoading={loading}
                loadingText="Wait..."
                onClick={handleUpdateUser}
              >Update
              </Button>
            </Box>

          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Center width="100%">
        <Box mt={{ sm: "40px", md: "100px" }} width={{ sm: "90%", md: "50%" }} mb="20">

          {user &&
            <>
              <Box width="100%" textAlign="center">
                <Avatar
                  size='lg'
                  bg='teal.500'
                  name={user.firstName + " " + user.lastName}
                  icon={<Icon as={FiUser} width="18px" height="18px" mt="1" />}
                />
                <Heading mt="4" size={{ sm: "md", md: "lg" }} textAlign="center" color="#45005E"> {user.firstName !== null && user.firstName}  {user.lastName !== null && user.lastName} </Heading>
              </Box>

              <SimpleGrid columns={{sm:1, md: 2}} spacing="3" width="100%" mt="5" background="#f2f9f9" p="5" borderRadius="10">
                <Box><Flex gap="3"><Icon as={FiPhone} width="18px" height="18px" mt="1" /> <Text color="#45005E" fontSize={{ sm: "md", md: "lg" }}>{user.phone}</Text> </Flex></Box>
                <Box> <Flex gap="3"><Icon as={FaEnvelope} width="18px" height="18px" mt="1" /> <Text color="#45005E" fontSize={{ sm: "md", md: "lg" }}>{user.email}</Text> </Flex></Box>
              </SimpleGrid>


              <VStack width="100%" spacing="6" mt="7" background="#f2f9f9" borderRadius="10" p="5">

                <Box width="100%">
                  <Flex direction={"row"} flexDirection={"start"} gap="3">
                    <Icon as={FiUser} width="18px" height="18px" mt="1" />
                    <Text fontFamily="ManropeRegular"
                      fontSize={{ sm: "md", md: "lg" }} color="#45005E">
                      {user.firstName}
                    </Text>
                    <Text fontFamily="ManropeRegular"
                      fontSize={{ sm: "md", md: "lg" }} color="#45005E">
                      {user.lastName}
                    </Text>
                  </Flex>
                </Box>


                <Box width="100%">

                  <Flex gap="2">
                    <Link to={"/p2p/changepassword"}>
                      <Button as="text" decoration={"underline"} variant='outline' size="md" >
                        Change Password
                      </Button>
                    </Link>
                    <Button
                  width="200px"
                  variant="brand"
                  size="md"
                  onClick={onDrawerOpen}
                >
                  Edit Profile
                </Button>
                    {/*
                    {user !== null && !user.deleted &&
                      <Button as="text" variant='outline' size="sm" decoration={"underline"} onClick={(e) => {
                        e.preventDefault();
                        onOpen();
                      }}>
                        <Text color="red" onClick={onOpen}>
                          Delete My Account
                        </Text>
                      </Button>
                    }*/}
                  </Flex>
                </Box>

              </VStack>

              <Box minH="100" width="100%" background="brand.500" p="5" borderRadius="5" mt="5"> 
                <Text color="#ffffff" fontSize={{ sm: "md", md: "lg" }}>Do you have your Graduaton Year Book? We might be interested, Please chat us on Whatsapp. &nbsp;&nbsp;
                <a href="https://api.whatsapp.com/send?phone=2349018898088&text=I%20need%20help"> <Text textDecoration="underline" as="span"> <Icon as={FaWhatsapp} width="20px" height="20px" mt="4"/> &nbsp;+2349018898088  </Text></a></Text>
            </Box>
 
            </>
          }
        </Box>
      </Center>
    </>
  );
}
