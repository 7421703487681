import axiosInstance from "./axiosInstance";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetOrganization = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/organization", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetOrganizationByID = async (organizationId, token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/organization/" + organizationId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateOrganization = async (organizationData, organizationId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/organization/" + organizationId,
      organizationData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateOrganizationByID = async (
  organizationData,
  organizationId,
  token
) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/organization/" + organizationId,
      organizationData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetOrganizations = async (organizationData, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/organization/getFiltered",
      organizationData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  GetOrganization,
  UpdateOrganizationByID,
  UpdateOrganization,
  GetOrganizationByID,
  GetOrganizations,
};
