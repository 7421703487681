import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Link } from 'react-router-dom';
import {
  Box,
  Text,
  Tag,
  Center,
  Button,
  Heading,
  Icon,
  HStack,
  VStack,
  Input,
  Flex,
  Stack,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Badge,
  Skeleton,
  SkeletonText,
  useToast,
  useClipboard,
  useDisclosure
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { SubmitKYC } from "services/userService";
import { GetVerifiableRecords, GetVerificationRequests, SendVerificationInvite } from "services/certificateService";
import { EmailIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { setUser } from "utils/userSlice";
import { FiShare2, FiLink2, FiCopy } from "react-icons/fi";
import { FacebookShare, TwitterShare, LinkedinShare, WhatsappShare, TelegramShare, EmailShare } from 'react-share-kit';

const { DateTime } = require("luxon");

export default function Dashboard() {
  const dispatch = useDispatch();
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isBreakdownOpen,  onOpen: onBreakdownOpen, onClose: onBreakdownClose } = useDisclosure();

  const [sendInviteLoading, setSendInviteLoading] = useState(false);
  const [verificationRequestLoading, setVerificationRequestLoading] = useState(false);
  const [verificationRequests, setVerificationRequests] = useState(null);

  const [shareUrl, setShareUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [title, setTitle] = useState("");
  const [hashtags, setHashtags] = useState(["Verify Credential", "Akowe Credential Verification"]);
  const { onCopy, hasCopied } = useClipboard(shareUrl);
  
  const [kycData, setKycData] = useState({
    firstName: userState.firstName? userState.firstName: "",
    lastName: userState.lastName? userState.lastName: "",
    middleName: userState.middleName? userState.middleName: "",
    nin: ""
  });
  const [inviteData, setInviteData] = useState({
    emailAddress: "",
  });
  const [verifiableId, setVerifiableId] = useState("");
  const [loading, setLoading] = useState(false);
  const [recordStat, setRecordStat] = useState({
    incompleteVerifications: 0
  });
  const [records, setRecords] = useState(null);
 
  const getVerifiableRecords = async () => {
    try {
      setLoading(true);
      const response = await GetVerifiableRecords(token);
      setLoading(false);
      if (response.success) {
        setRecords(response?.data);
        const incompleteCount = response?.data.filter(item => item.verified===false).length;
        setRecordStat({
          incompleteCount
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getVerificationRequests = async () => {
    try {
      setVerificationRequestLoading(true);
      const response = await GetVerificationRequests(token);
      setVerificationRequestLoading(false);
      if (response.success) {
        setVerificationRequests(response?.data);
      }
    } catch (error) {
      setVerificationRequestLoading(false);
    }
  };

  const handleSendInvite = async () => {
    try {
      setSendInviteLoading(true);
      const response = await SendVerificationInvite(inviteData, verifiableId, token);
      setSendInviteLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: "Verification invite has been sent successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setSendInviteLoading(false);
    }
  }

  const handleSubmitKYC = async () => {
    try {
      setLoading(true);
      const response = await SubmitKYC(kycData, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: "KYC has been verified successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        const user  = userState;
        dispatch(setUser({...user, firstName: kycData.firstName, middleName: kycData.middleName, lastName: kycData.lastName, kycVerified: true}));
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    getVerifiableRecords();
    getVerificationRequests();
  }, []);

  useEffect(() => {
    if (shareUrl === "") return;
    onCopy();
  }, [shareUrl]);


  return (
    <> 
     <Modal onClose={onBreakdownClose} size={"2xl"} isOpen={isBreakdownOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Academic Credential Score Breakdown</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              width="100%"
              pb="20"
            > 
               <HStack width="100%">
                  <Box><Text fontSize={{ sm: "md", md: "lg" }} fontWeight={"Bold"}> Direct Integration with Tertiary Institutions</Text></Box>
                  <Box> <Tag fontSize={{ sm: "md", md: "lg" }} background={"brand.500"} color="white"> 50% </Tag></Box>
               </HStack>
             
               <HStack width="100%" mt="5">
                  <Box><Text fontSize={{ sm: "md", md: "lg" }} fontWeight={"Bold"}> Public Academic Records</Text></Box>
                  <Box> <Tag fontSize={{ sm: "md", md: "lg" }} background={"brand.500"} color="white">  20% </Tag></Box>
               </HStack>

               <HStack width="100%" mt="5">
                  <Box><Text fontSize={{ sm: "md", md: "lg" }} fontWeight={"Bold"}> Peer 2 Peer Verification</Text></Box>
                  <Box> <Tag fontSize={{ sm: "md", md: "lg" }} background={"brand.500"} color="white">  20% </Tag></Box>
               </HStack>

               <HStack width="100%" mt="5">
                  <Box><Text fontSize={{ sm: "md", md: "lg" }} fontWeight={"Bold"}> NIN Verification</Text></Box>
                  <Box> <Tag fontSize={{ sm: "md", md: "lg" }} background={"brand.500"} color="white">  10% </Tag></Box>
               </HStack>

            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    <Modal onClose={onClose} size={"2xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Verification Invite</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              width="100%"
            > 
               <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Email Address</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="Email Address"
                      defaultValue={inviteData.emailAddress}
                      onChange={(e) => {
                        setInviteData(prevInviteData => ({
                          ...prevInviteData,
                          emailAddress: e.target.value,
                        }));
                      }}
                    />
               </FormControl>

            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="brand" isLoading={sendInviteLoading} loadingText="..Sending" onClick={handleSendInvite}>Send Invite</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    
    <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
        
    <Box width={{sm: "100%", md: "90%"}}>
              
    <SimpleGrid spacing={4} mt="5" columns={{sm: 1, md: 2}}>
          {/* Request Verification Button */}
          <Link to={"/p2p/credentials/search"}>
          <Button
          colorScheme="teal"
          size="lg"
          borderRadius="md"
          >
          Request New Verification
          </Button>
          </Link>
          {/* Verifier Button with Badge */}
          <Button
          colorScheme="purple"
          size="lg"
          borderRadius="md"
          position="relative"
          >
          Incomplete Verifications
          <Badge
            colorScheme="red"
            borderRadius="full"
            ml={2}
            fontSize="0.8em"
          >
            {recordStat.incompleteCount}
          </Badge>
          </Button>
          </SimpleGrid>



            
          <Box mt="10"><Heading size={{sm: "md", md: "lg"}}>Your Academic Records</Heading></Box>
         <SimpleGrid columns={{sm: 1, md: 2}} spacing="3">
         { 
          records && records.map((record)=>
            <Card background="brand.100"  boxShadow="md">
          <Box width="100%">
          <HStack>
            <Box width="80%"><Flex alignItems="center" mb={4}>
              <Text fontWeight="bold" fontSize={{sm: "md", md: "lg"}}>
              {record?.certificate.degree}, {record?.certificate.course} 
              </Text>
            </Flex>
            <Text color="gray.500" fontSize={{sm: "md", md: "lg"}} mb={4}>
            {record?.certificate?.institution?.institutionName }, {record?.certificate?.institution?.state }
            </Text></Box>
            <Box width="20%">
               <Flex><Heading textColor={"gray.500"}>{20 + 10 + record?.verificationScore}%</Heading>&nbsp;&nbsp;<Icon color="brand.500" mt="2" as="FiInfo" height="18" width="18" onClick={onBreakdownOpen} /></Flex>
            </Box>
         </HStack>


 {/* Verification Status */}
 <VStack spacing={2} align="stretch" mb={6}>
   <SimpleGrid spacing={2} mt="3" columns={{sm: 1, md: 2}}>
    <Box>
     <Tag colorScheme={"teal"}>Peer to Peer Verification Count {record?.verificationCount} </Tag>
     </Box> 
     <Box>
      <Tag colorScheme={"teal"}>Peer 2 Peer Verification Score {record?.verificationScore}</Tag> 
      </Box> 
   </SimpleGrid>
   <Text color="red.500" fontSize="sm">
     You can invite as many people as possible, you can have a maximum verification score of 20
   </Text>
 </VStack>

 {/* Verification Link */}
 <Box bg="gray.200" p={3} borderRadius="md" mb={4}>
   <Link fontSize="sm" to={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`} isExternal>
     https://verify.akowe.app/p2p/credentials/invite/{record.certificate._id}
   </Link>
 </Box>

 <Flex gap="2" flexDirection="row" flexWrap="wrap">
   <FacebookShare url={shareUrl}
     quote={`I am inviting you to assist in verifying my record; ${record.certificate?.degree} in ${record.certificate?.course} at ${record.certificate?.institution?.institutionName}, ${record.certificate?.institution?.city}, ${record.certificate?.institution?.state} - Via Akowe.app 🔥`}
     hashtag={hashtags} />
   <TwitterShare
     url={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`}
     title={`I am inviting you to assist in verifying my record; ${record.certificate?.degree} in ${record.certificate?.course} at ${record.certificate?.institution?.institutionName}, ${record.certificate?.institution?.city}, ${record.certificate?.institution?.state} - Via Akowe.app 🔥`}
     hashtags={hashtags} />
   <LinkedinShare
     url={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`} />
   <WhatsappShare
     url={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`}
     title={`I am inviting you to assist in verifying my record; ${record.certificate?.degree} in ${record.certificate?.course} at ${record.certificate?.institution?.institutionName}, ${record.certificate?.institution?.city}, ${record.certificate?.institution?.state} - Via Akowe.app 🔥`}
     separator=":: "
   />
   <TelegramShare url={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`} />
   <EmailShare
     url={`https://verify.akowe.app/p2p/credentials/invite/${record.certificate._id}`}
     subject={'I am inviting you to assist in verifying my academic record via Akowe.app'}
     body={`I am inviting you to assist in verifying my record; ${record.certificate?.degree} in ${record.certificate?.course} at ${record.certificate?.institution?.institutionName}, ${record.certificate?.institution?.city}, ${record.certificate?.institution?.state} - Via Akowe.app 🔥`}
   />
 </Flex>

 {/* Copy Link Button */}
 <Flex gap={2} mt="5">
 {/*<Button
   leftIcon={<CopyIcon />}
   colorScheme="brand"
   size="sm"
   onClick={() => navigator.clipboard.writeText("https://verify.akowe.app/p2p/credential/verify/"+record.inviteCode)}
 >
   Copy Link
 </Button>*/}
 <Button
   leftIcon={<EmailIcon />}
   colorScheme="brand"
   size="sm"
   onClick={() => { setVerifiableId(record._id); onOpen() } }
   >
   Send Invite
 </Button>
 </Flex>
</Box>

     </Card>
          )}

       </SimpleGrid>


       <Box mt="10"><Heading size={{sm: "md", md: "lg"}}>Sent Verification Requests</Heading></Box>

       <SimpleGrid columns={{sm: 1, md:2}} mt="5" spacing={"2"}>
        {
         verificationRequests && verificationRequests.map((record) =>
          <Box p="10" background="brand.100" borderRadius="10" minHeight={"100"}>
            <Text mt="5" fontSize={{ sm: "md", md: "lg" }} color="#45005E">You sent a request to <Text as="span" color="brand.500">{record.verifier} </Text> to assit in verifying your record; <Text as="span" color="brand.500"> {record.certificate?.degree}</Text> in <Text as="span" color="brand.500">{record.certificate?.course}</Text> at  <Text as="span" color="brand.500">{record.certificate?.institution?.institutionName}, {record.certificate?.institution?.city}, {record.certificate?.institution?.state}</Text> </Text> 
            <Button
        colorScheme="brand"
        size="sm"
        mt="3"
        >
        {record.accepted? "Accepted": "Pending"}
      </Button>
          </Box>)
         }
        </SimpleGrid>


       </Box>


       </Box>
    </>
  );
}

const LoadingHistory = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};