import { Outlet } from "react-router-dom";
import {
  Box,
  Text,
  HStack,
  Flex,
  Link as ChakraLink,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";

const CredentialsLayout = () => {
  return (
    <Box p="4" width="100%">
      <Outlet />
    </Box>
  );
};

export default CredentialsLayout;
