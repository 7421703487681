import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Flex,
  Box,
  Image,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import { UserForgotPassword } from "services/userService";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha
} from "react-simple-captcha";

import illustrationImage from "assets/images/login1.png";
import Logo from "assets/images/logo.svg";

function Forgot() {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  /*
  const validationSchema = Yup.object({
    email: Yup.string().email().required().label("Email Address"),
    password: Yup
      .string()
      .min(6)
      .matches(
        /^(?=.*[a-z])/,
        "Must contain at least one lowercase character"
      )
      .matches(
        /^(?=.*[A-Z])/,
        "Must contain at least one uppercase character"
      )
      .matches(/^(?=.*[0-9])/, "Must contain at least one number")
      .matches(
        /^(?=.*[!@#%&])/,
        "Must contain at least one special character ?=.*!@#%&"
      )
      .required()
      .label("Password")
  });*/

  const handleForgot = async () => {
    try {
      if (!validateCaptcha(captchaToken)) {
        setCaptchaToken("");
        toast({
          title: "Invalid Captcha Entered",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const user = { email }
      const response = await UserForgotPassword(user);
      setLoading(false);
      if (response && (response.error || !response.success)) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });

        setEmail("");
        setCaptchaToken("");
        // navigate("/auth/otp", { state: { email: response.data.email } });
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(4, "#ffffff", "#004c4d", "upper");
  }, []);

  return (
    <DefaultAuth illustrationImage={illustrationImage} heading="Verification of Academic Records">

      <Flex width={"100%"} justify={"center"}>
        <Stack
          spacing={4}
          mx={"auto"}
          mt={{ sm: "30px", md: "0px" }}
          w={{ base: "95%", sm: "90%", md: "60%", lg: "50%" }}
        >
          <Stack align={"left"}>
            <Image src={Logo} width="70px" height="auto" />
            <Heading fontFamily="ManropeSemiBold" fontWeight={"bold"} size={{ sm: "sm", md: "md" }} textAlign={"left"} color="#45005E">
              Forgot Password
            </Heading>
          </Stack>

          <Box width="100%" pb="20" mt="2">
            <Stack spacing={4} width="100%">
              <FormControl id="email" isRequired>
                <FormLabel fontFamily="ManropeSemiBold">
                  Email Address
                </FormLabel>
                <Input
                  variant="main"
                  type="email"
                  maxLength={50}
                  value={email}
                  defaultValue={email}
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormControl>

              <Flex
                columns={{ sm: 2, md: 2 }}
                direction={{ sm: "row", md: "row" }}
                gaps="2">
                <Box>
                  <Input
                    variant="main"
                    type={"text"}
                    maxLength={4}
                    value={captchaToken}
                    defaultValue={captchaToken}
                    color="black"
                    onChange={(e) => {
                      setCaptchaToken(e.target.value);
                    }}
                    placeholder="Enter Captcha"
                  />
                </Box>
                <Box pt="2">
                  <LoadCanvasTemplate reloadText="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Refresh" reloadColor="#008081" />
                </Box>
              </Flex>
            </Stack>
          </Box>

          <Stack spacing={3} mt={4}>
            <Button
              variant="brand"
              isLoading={loading}
              loadingText="Wait"
              onClick={handleForgot}
              mb="10px"
              type="submit"
            >
              Send Password Reset Link
            </Button>

            <Text align={"center"} color={"dark.600"} fontSize="md">
              Don't have an account? &nbsp;
              <Text color="brand.500" as="span">
                <Link to={"/auth/signup"}>
                  Signup
                </Link>
              </Text>
            </Text>
          </Stack>
        </Stack>
      </Flex>
    </DefaultAuth>
  );
}

export default Forgot;
