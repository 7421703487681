import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Heading, Text, VStack, SimpleGrid, Center, FormControl, FormLabel, Textarea, Select, Button, Icon, useToast } from "@chakra-ui/react";
import { SendSupport } from "services/miscService";
import { FiPhone } from "react-icons/fi";
import { FaEnvelope, FaHeadphonesAlt, FaWhatsapp } from "react-icons/fa";

export default function Support() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await SendSupport(supportData, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setSupportData({
          type: "",
          message: ""
        })
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  }

  const [supportData, setSupportData] = useState({
    type: "",
    message: ""
  });

  return (
    <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
      <Box mb="5">
        {" "}
        <Text
          color={"black"}
          mb="4px"
          fontFamily={"ManropeSemiBold"}
          fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
        > We are here to listen to you &nbsp; <Icon color="brand.500" as={FaHeadphonesAlt} width="20px" height="20px" />
        </Text>
      </Box>
      <Center width="100%">
        <Box mt={{ sm: "20px", md: "50px" }} width={{ sm: "90%", md: "50%" }} mb="20">
          <VStack width="100%" spacing="6" background="#f2f9f9" borderRadius="10" p="10" pb="8">
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="4" width="100%" mt="10">
              <Box>
                <Button
                  variant="brand">
                  <Icon as={FaEnvelope} width="20px" height="20px" /> &nbsp;
                  support@akowe.app
                </Button>
              </Box>
              <Box>
                <a href="https://api.whatsapp.com/send?phone=2349018898088&text=I%20need%20help">
                  <Button
                    variant="brand">
                    <Icon as={FaWhatsapp} width="20px" height="20px" /> &nbsp;
                    +2349018898088
                  </Button>
                </a>
              </Box>
            </SimpleGrid>

            <Box width="100%" mt="5">
              <Heading color="#45005E" size={{ sm: "sm", md: "md" }}> File a Complaint </Heading>
            </Box>

            <Box mt="2" width="100%">
              <FormControl>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Type of Complaint</FormLabel>
                <Select background="white" borderRadius="15" height="50px" defaultValue={supportData && supportData.type} onChange={(e) => setSupportData((prev) => ({ ...prev, type: e.target.value }))}>
                  <option value={" "}>
                    --- Select type of complaint ---
                  </option>
                  <option value={"Account Management"}>
                    Account Management
                  </option>
                  <option value={"Technical Support"}>
                    Technical Support
                  </option>
                  <option value={"Billing and Payments"}>
                    Billing and Payments
                  </option>
                  <option value={"Legal and Compliance"}>
                    Legal and Compliance
                  </option>
                  <option value={"Feedback and Suggestions"}>
                    Feedback and Suggestions
                  </option>
                </Select>
              </FormControl>
            </Box>

            <Box mt="2" width="100%">
              <FormControl>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Complaint</FormLabel>
                <Textarea background="white" height="50px" borderRadius="15" row="40" value={supportData.message} onChange={(e) => setSupportData((prev) => ({ ...prev, message: e.target.value }))}></Textarea>
              </FormControl>
            </Box>

            <Box width="100%" textAlign={"center"} mt="4" mb="7">
              <Button
                variant="brand"
                size="md"
                isLoading={loading}
                loadingText="..Wait"
                onClick={handleSubmit}>
                Send a Complaint
              </Button>
            </Box>
          </VStack>
        </Box>
      </Center>
    </Box>
  );
};
