import React from "react";
import { Icon } from "@chakra-ui/react";
import { FiUsers, FiUser, FiHome, FiSearch, FiFile, FiUserPlus, FiCreditCard, FiSettings } from "react-icons/fi";
import { FaCog, FaKey, FaBriefcase, FaPlus, FaInfoCircle, FaHeadphonesAlt, FaHistory } from "react-icons/fa";

import Dashboard from "views/p2p/dashboard";

import CredentialsLayout from "views/p2p/credentials/layout";
import Credentials from "views/p2p/credentials";
import Search from "views/p2p/credentials/search";
import Verify from "views/p2p/credentials/verify";
import Invite from "views/p2p/credentials/invite";
import Settings from "views/p2p/settings/index";
import ChangePassword from "views/p2p/settings/changepassword";

const routes = [
  {
    name: "DASHBOARD",
    nameAbbrv: "Dashboard",
    layout: "/p2p",
    path: "dashboard",
    icon: <Icon as={FiHome} width="20px" height="20px" />,
    component: <Dashboard />,
    collapse: false,
    allow: ["Personal"],
  },
  {
    name: "CREDENTIALS",
    nameAbbrv: "Credentials",
    layout: "/p2p",
    path: "credentials",
    icon: <Icon as={FiFile} width="20px" height="20px" />,
    component: <CredentialsLayout />,
    collapse: true,
    allow: ["Personal"],
    items: [
      {
        name: "VERIFICATION",
        layout: "/p2p",
        path: "verification",
        icon: (
          <Icon as={FiSearch} width="20px" height="20px" />
        ),
        component: <Credentials />,
        collapse: false,
        show: true,
        allow: ["Personal"],
      },
      {
        name: "SEARCH",
        layout: "/p2p",
        path: "search",
        icon: (
          <Icon as={FiSearch} width="20px" height="20px" />
        ),
        component: <Search />,
        collapse: false,
        show: false,
        allow: ["Personal"],
      },
      {
        name: "VERIFY",
        layout: "/p2p",
        path: "verify/:inviteCode",
        icon: (
          <Icon as={FiSearch} width="20px" height="20px" />
        ),
        component: <Verify />,
        collapse: false,
        show: false,
        allow: ["Personal"],
      },
      {
        name: "INVITE",
        layout: "/p2p",
        path: "invite/:certificateId",
        icon: (
          <Icon as={FiSearch} width="20px" height="20px" />
        ),
        component: <Invite />,
        collapse: false,
        show: false,
        allow: ["Personal"],
      },
    ]
  },
  {
    name: "SETTINGS",
    nameAbbrv: "Settings",
    layout: "/p2p",
    path: "settings",
    icon: <Icon as={FiUser} width="20px" height="20px" />,
    component: <Settings />,
    collapse: false,
    allow: ["Personal"],
  },
  {
    name: "CHANGE PASSWORD",
    layout: "/p2p",
    path: "changepassword",
    icon: (
      <Icon as={FaKey} width="20px" height="20px" />
    ),
    component: <ChangePassword />,
    collapse: false,
    show: false,
    allow: ["personal"],
  },
]
export default routes;


export const smallRoutes = [
  {
    name: "DASHBOARD",
    nameAbbrv: "Dashboard",
    layout: "/p2p",
    path: "dashboard",
    icon: <Icon as={FiHome} width="20px" height="20px" />,
    component: <Dashboard />,
    collapse: false,
    allow: ["Personal"],
  },
  {
    name: "CREDENTIALS",
    nameAbbrv: "Credentials",
    layout: "/p2p",
    path: "credentials/verification",
    icon: <Icon as={FiHome} width="20px" height="20px" />,
    component: <Credentials />,
    collapse: false,
    allow: ["Personal"],
  },
  {
    name: "SETTINGS",
    nameAbbrv: "Settings",
    layout: "/p2p",
    path: "settings",
    icon: <Icon as={FiUser} width="20px" height="20px" />,
    component: <Settings />,
    collapse: false,
    allow: ["Personal"],
  },
]
  